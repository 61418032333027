// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 通过审核状态查询dbtId列表
export const getDbtIdsByApprovalStatus = () =>
  request('/payAdminServer/product/getDbtIdsByApprovalStatus', null, 'POST_JSON', null)

// 通过dbt查询产品渠道
export const getProdTypeByDbtId = (data: { dbtId: string | null | number }) =>
  request('/payAdminServer/product/getProdTypeByDbtId', null, 'POST_JSON', data)

// 查询商品列表
export const getProductList = (data: { approvalStatuses: number[]; dbtId: string; prodChnl: string }) =>
  request('/payAdminServer/product/getProductList', null, 'POST_JSON', data)

// 发布商品
export const publishProduct = (data: { productList: { id: number }[] }) =>
  request('/payAdminServer/product/publishProduct', null, 'POST_JSON', data)

// 通过审核
export const passProduct = (data: { productList: { id: number }[] }) =>
  request('/payAdminServer/product/passProduct', null, 'POST_JSON', data)

// 驳回审核
export const rejectProduct = (data: { productList: { id: number }[]; rejectMsg: string }) =>
  request('/payAdminServer/product/rejectProduct', null, 'POST_JSON', data)

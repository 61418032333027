import { awaitWrap, OK } from '@/common';
import { batchChangePrice, submitGoods } from '@/api/goodsSetting';
import { passProduct, publishProduct } from '@/api/goodsJudge';
import { message } from 'ant-design-vue';
export function usePass(cb) {
  const loading = ref(false); // loading
  /*
  *  先提交商品，商品提交成功后才能提交审核
  *   自动通过审核，审核通过后才能发布
  *   发布商品，有可能只有部分商品发布成功
  * */
  const submitJudgeAndPass = async dataSource => {
    loading.value = true;
    let params = {
      productList: dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(item => item.id)
    };
    const [err, res] = await awaitWrap(submitGoods(params));
    if (err) return loading.value = false;
    if (res && res.code === OK) {
      // 自动审核通过
      const [err2, res2] = await awaitWrap(passProduct(params));
      if (err2) return loading.value = false;
      if (res2 && res2.code === OK) {
        const [err3, res3] = await awaitWrap(publishProduct(params));
        if (err3) return loading.value = false;
        if (res3 && res3.code === OK) {
          message.success('提交成功');
          cb();
        } else if (res3 && res3.code === 500) {
          message.success(res3.msg);
          cb();
        }
      }
      loading.value = false;
    }
  };
  const changePrice = async (dataSource, changePriceType = 0) => {
    loading.value = true;
    let params = {
      productList: dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(item => {
        return {
          id: item.id
        };
      }),
      changePriceType
    };
    const [err, res] = await awaitWrap(batchChangePrice(params));
    if (err) return loading.value = false;
    if (res && res.code === OK) {
      cb();
    }
    loading.value = false;
  };
  return {
    loading,
    submitJudgeAndPass,
    changePrice
  };
}